import React from 'react';
import classnames from 'classnames';
import { formatNumberToAmount } from 'services/number.service';
import { Spin, Tag } from 'antd';
import { getStatusColor } from 'services/status.service';
import { Status } from 'enums/statusEnum';
import { Transaction } from '../types';

export const columns = [
    {
        title: 'Driver ID',
        dataIndex: 'user_id',
        key: 'user_id',
    },
    {
        title: 'Amount',
        key: 'amount',
        render: (transaction: Transaction) => (
            <div className={classnames(transaction?.direction == 'CREDIT' ? 'text-green-600' : 'text-red-600')}>
                {`PHP ${formatNumberToAmount(transaction?.amount)}`}
            </div>
        ),
    },
    {
        title: 'Reason for Top-up or Deduction',
        dataIndex: 'reason',
        key: 'reason',
    },
    {
        title: 'Direction',
        dataIndex: 'direction',
        key: 'direction',
    },
    {
        title: 'Wallet Type',
        dataIndex: 'wallet_type',
        key: 'wallet_type',
    },
    {
        title: 'Status',
        key: 'status',
        render: (transaction: Transaction) => {
            const color = getStatusColor(transaction.status);
            return (
                <div className="flex items-center gap-4">
                    <Tag color={color} className="uppercase">
                        {transaction.status}
                    </Tag>
                    {transaction.status === Status.PROCESSING && <Spin />}
                </div>
            );
        },
    },
    {
        title: 'Failed Response',
        key: 'failed_response',
        render: (transaction: Transaction) => transaction?.failed_reason ?? '-',
    },
];
