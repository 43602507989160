import React, { useCallback, useContext, useRef, useState } from 'react';
import { Button, Table } from 'components/basic';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import useApi from 'hooks/useApi';
import { bulkAdjustBalance, getAdjustments } from 'services/message.service';
import papa from 'papaparse';
import { Alert, message, notification } from 'antd/lib';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { downloadCSVFile } from 'services/file.service';
import { AuthUserContext } from 'components/context/AuthUserContext';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';
import { columns } from './columns';
import useMount from 'hooks/useMount';
import useFilter from 'hooks/useFilter';
import { FilterState, Pagination, Transaction, TransactionRequest } from './types';

const downloadTemplate = () => {
    const tempData = `driver_id,amount,reason,direction,wallet_type
11b19c68-f913-484f-823e-32e2468ca634,100,test,CREDIT,CREDIT
1d70cf4a-1179-4b52-ad65-d66adaf5a1d7,200,test,DEBIT,CASH
b0782bbb-06b7-474b-bf4f-853456b370af,20,test,CREDIT,CASH
2d05668f-9214-455c-91e7-f3380f3487e9,1000,test,DEBIT,CASH`;
    downloadCSVFile(tempData, 'bulk-topup-deduct-template-v1');
};

const BulkTopUpsDeductionsV2 = () => {
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState<Pagination>({
        defaultCurrent: 1,
        defaultPageSize: 10,
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const userRole = useContext(AuthUserContext);
    const hasActionAccess = (page: string) => {
        return (
            getPermissions(userRole, page)?.includes(Accessibility.ALL) ||
            getPermissions(userRole, page)?.includes(Accessibility.EDIT)
        );
    };

    const { request: requestAdjustBalance } = useApi({
        api: bulkAdjustBalance,
    });

    const { request: requestListOfAdjustments, loading } = useApi({
        api: getAdjustments,
    });

    const { modifyFilters, filterState, requestState } = useFilter({
        page_size: 10,
        page: 1,
    });

    const fetchAdjustments = useCallback(
        async (filterState: Partial<FilterState>) => {
            try {
                const response = await requestListOfAdjustments(filterState);

                if (response.error) {
                    notification.error({
                        message: 'Error',
                        description: 'Error fetching adjustment details.',
                        duration: 3,
                    });
                    return;
                }

                setData(response.data.adjustment_jobs);

                const { page, page_size, total_count } = response.data.pagination;
                const paginationResponse: Pagination = {
                    ...pagination,
                    current: page,
                    pageSize: page_size,
                    total: total_count,
                };

                setPagination(paginationResponse);
            } catch (error) {
                notification.error({
                    message: 'Unexpected Error',
                    description: 'There has been an unexpected error. Please try again later.',
                    duration: 3,
                });
            }
        },
        [requestListOfAdjustments, pagination],
    );

    useMount(() => {
        fetchAdjustments(filterState);
    });

    const onTableChange = useCallback(
        async (pagination: Partial<Pagination>) => {
            const { current, pageSize } = pagination;

            const { requestState } = await modifyFilters({
                page_size: pageSize,
                page: current,
            });

            await fetchAdjustments(requestState || {});
        },
        [fetchAdjustments, modifyFilters],
    );

    const uploadCsvData = useCallback(
        async (csvResult: Transaction[]) => {
            try {
                const response = await requestAdjustBalance({
                    body: {
                        adjustments: csvResult,
                    },
                });

                if (response.error) {
                    notification.error({
                        message: 'Error',
                        description: `${response.error.errorCode}: ${response.error.message}`,
                        duration: 3,
                    });
                    return;
                }

                notification.success({
                    message: 'Success',
                    description: 'CSV File successfully uploaded',
                    duration: 3,
                });
                fetchAdjustments(requestState || {});
            } catch (e) {
                notification.error({
                    message: 'Error',
                    description: 'Bulk Top-up/Deduction encountered an error.',
                    duration: 3,
                });
            }
        },
        [requestAdjustBalance, requestState, fetchAdjustments],
    );

    const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target?.files?.[0]) {
            papa.parse(event.target.files[0], {
                header: true,
                skipEmptyLines: true,
                complete: function (result: any) {
                    const max_rows = 10000;
                    if (result.data.length > max_rows) {
                        // Maximum of 10000 rows per upload
                        notification.error({
                            message: 'Error',
                            description: `Maximum of ${max_rows} Rows per upload`,
                            duration: 3,
                        });

                        return;
                    }
                    try {
                        let arrayObj = result.data.map((d: TransactionRequest) => {
                            return {
                                ...d,
                                user_id: d.driver_id,
                                amount: parseFloat(d.amount),
                            };
                        });
                        uploadCsvData(arrayObj);
                    } catch (err) {
                        message.error('Please upload a CSV file with a valid template.');
                    }
                },
            });
        }
    };

    const handleFileUpload = () => {
        hiddenFileInput?.current?.click();
    };

    return (
        <ListLayout title="Bulk Top-up and Deductions">
            <div className="flex justify-between">
                <Alert showIcon type="warning" message="Please upload file in .csv format only." />

                <div className="flex flex-row gap-1">
                    {hasActionAccess(PagesEnum.TOP_UP_AND_DEDUCTIONS) && (
                        <div>
                            <input
                                hidden
                                id="upload-file"
                                type="file"
                                accept=".csv"
                                name="file"
                                ref={hiddenFileInput}
                                onChange={handleFile}
                            ></input>
                            <Button type="primary" onClick={handleFileUpload} icon={<UploadOutlined />}>
                                Upload CSV
                            </Button>
                        </div>
                    )}
                    <Button type="ghost" target="_blank" onClick={() => downloadTemplate()} icon={<DownloadOutlined />}>
                        Download Template
                    </Button>
                </div>
            </div>
            <Table
                columns={columns}
                dataSource={data}
                loading={loading}
                pagination={{
                    ...pagination,
                    showSizeChanger: true,
                }}
                onChange={onTableChange}
            />
        </ListLayout>
    );
};

export default BulkTopUpsDeductionsV2;
