import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Tabs, Skeleton } from 'antd/lib';
import { Card } from 'components';
import { getSegmentById } from 'services/message.service';
import useMount from 'hooks/useMount';
import { useParams } from 'react-router-dom';
import Text from 'components/basic/Typography/Text';
import useApi from 'hooks/useApi';
import DetailContentLayout from 'components/layouts/ContentLayout/DetailContentLayout';
import DriverSegmentSummary from './DriverSegmentSummary';
import { AuthUserContext } from 'components/context/AuthUserContext';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';
import { useNavigate } from 'react-router-dom';
import Drivers from './Drivers';
import DriverServiceArea from './DriverServiceArea';

const DriverSegmentDetail = ({ page = 'overview' }) => {
    const navigate = useNavigate();
    let { id } = useParams();
    const [currentPage, setCurrentPage] = useState(page);
    const userRole = useContext(AuthUserContext);
    const hasActionAccess = (page: string) => {
        return (
            getPermissions(userRole, page)?.includes(Accessibility.ALL) ||
            getPermissions(userRole, page)?.includes(Accessibility.VIEW) ||
            getPermissions(userRole, page)?.includes(Accessibility.EDIT)
        );
    };

    useMount(() => {
        fetchSegmentById(id + '');
    });
   
    const { request, result, loading } = useApi({
        api: getSegmentById,
    });


    const fetchSegmentById = useCallback(
        async (segmentId: string) => {
            try {
                await request({ id: segmentId || id });
            } catch (error) {
                throw error;
            }
        },
        [request, id],
    );

    if (loading) {
        return (
            <Card>
                <Skeleton />
            </Card>
        );
    }

    const data = result?.data || {};    

    return (
        <DetailContentLayout fallbackRoute='/driver-segments'>
            <div className='mt-4'>
                <div className='rounded border border-dashed border-slate-200 flex flex-col gap-2 w-full h-auto p-sm mb-4'>
                    <DriverSegmentSummary data={[data, id]} page="driver-segments" refreshData={() => request({ id })} />
                </div>
                <div>

                    <Tabs
                        activeKey={currentPage} // ✅ Keeps state in sync
                        tabBarGutter={50}
                        onChange={e => {
                            navigate(`/driver-segments/overview/${id}/${e}`);
                            setCurrentPage(e);
                        }}
                        items={[
                            {
                                label: <Text className="uppercase">DRIVERS</Text>,
                                key: 'drivers',
                                children: <Drivers segmentData={data} tableData={data?.data?.driver_list} exportData={data?.data?.rules[0]?.config}/>,
                            },
                            {
                                label: <Text className="uppercase">DRIVER SERVICE AREA</Text>,
                                key: 'driver-service-area',
                                children: <DriverServiceArea segmentData={data} tableData={data?.data?.rules[0]?.config} />,
                            },
                        ]}
                    />
                </div>
            </div>
        </DetailContentLayout>
    );
};

export default DriverSegmentDetail;
