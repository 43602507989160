import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Button, Checkbox, Form, Modal, Select, Space, Tag, notification } from 'antd';
import { Table } from 'components';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Dropdown, Text } from 'components/basic';
import { DownOutlined } from '@ant-design/icons';
import classnames from 'clsx';
import useModal from 'hooks/useModal';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import type { RangeValue } from 'rc-picker/lib/interface';
import useMount from 'hooks/useMount';
import { getLayerById, getLayersList, updateDriverSegment } from 'services/message.service';
import useApi from 'hooks/useApi';
import moment from 'moment';

const DriverServiceArea = ({ tableData = {}, segmentData = { data: [] } }: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    const confirmModal = useModal();
    let { id } = useParams();
    const { Option } = Select;
    const [segmentName, setSegmentName] = useState(location.state?.segmentName || 'Default Segment Name');
    const segmentId = id || '';
    const [segmentDescription, setSegmentDescription] = useState(location.state?.segmentDescription || 'Default Segment Description')
    const [modalForm] = Form.useForm();
    const [selectedDays, setSelectedDays] = useState<CheckboxValueType[]>([]);
    const [selectedRange, setSelectedRange] = useState<RangeValue<moment.Moment> | null>(null);
    const [serviceType, setServiceType] = useState<string>('');
    const [status, setStatus] = useState<boolean>(false);
    const [layers, setLayers] = useState<any[]>([]);
    const [selectedLayerName, setSelectedLayerName] = useState<string | null>(null);
    const [selectedLayerId, setSelectedLayerId] = useState<string | null>(null);
    const [zones, setZones] = useState<any[]>([]);
    const [zoneOptions, setZoneOptions] = useState<{ value: string; label: string }[]>([]);
    const [selectedZones, setSelectedZones] = useState<string[]>([]);
    const [zoneArray, setZoneArray] = useState<any[]>([]);
    const allZoneIds = zones?.map(zone => zone.id);

    const ColumnActions = useCallback(
        (user: any) => {
            console.log(user,'test user')
            if (!user || Object.keys(user).length === 0) {
                return null; 
            }
            return (
                <Dropdown
                    menu={{
                        items: [
                            {
                                label: 'View & Edit',
                                key: '1',
                                onClick: () => {
                                    confirmModal.show();
                                },
                            }
                        ],
                    }}
                >
                    <Button type="ghost">
                        <DownOutlined />
                    </Button>
                </Dropdown>
            );
        },
        [confirmModal]
    );
    const memoizedTableDataArray = useMemo(() => {
        return Array.isArray(tableData) ? tableData : [tableData];
    }, [tableData]);

   

    useEffect(() => {
        if (memoizedTableDataArray?.length) {
            setSelectedLayerName(memoizedTableDataArray[0]?.layer_name);
            setSelectedDays(memoizedTableDataArray[0]?.days);
            setServiceType(memoizedTableDataArray[0]?.service_type);
            setZones(memoizedTableDataArray[0]?.values);

            const zoneOptions = memoizedTableDataArray[0]?.values?.map((zone: any) => ({
                value: zone.id,
                label: zone.name,
            }));
            setZoneOptions(zoneOptions);
            setZoneArray(zoneOptions?.map((zone: { label: any }) => zone.label));
            setSelectedZones(memoizedTableDataArray[0]?.values?.map((zone: { id: any }) => zone.id));
            setStatus(memoizedTableDataArray[0]?.status);
            setSelectedLayerId(memoizedTableDataArray[0]?.layer_id);
            if (memoizedTableDataArray[0].time?.length) {
                const formattedTime: RangeValue<moment.Moment> = [
                    moment(memoizedTableDataArray[0]?.time[0].start, "HH:mm"),
                    moment(memoizedTableDataArray[0]?.time[0].end, "HH:mm"),
                ];
                setSelectedRange(formattedTime);
            } else {
                setSelectedRange(null);
            }
            setSegmentName(segmentData?.data?.name);
            setSegmentDescription(segmentData?.data?.description);
        }
    }, [memoizedTableDataArray, segmentData?.data?.name, segmentData?.data?.description]);
    const columns = [
        {
            title: 'Layer ID',
            dataIndex: 'layer_id',
            key: 'layer_id',
        },
        {
            title: 'Layer Name',
            key: 'name',
            render: (record: any) => (
                <Text className={classnames({ 'text-gray-500': !record?.layer_name })}>
                    {record?.layer_name || '-'}
                </Text>
            ),
        },
        {
            title: 'Service Area',
            key: 'layer_id',
            render: (record: any) => (
                <Text>
                    {record?.values?.length || '-'}
                </Text>
            ),
        },
        {
            title: 'Day(s)',
            key: 'days',
            render: (record: any) => (
                <Text>
                    {Array.isArray(record?.days) ? record?.days.join(', ') : '-'}
                </Text>
            ),
        },
        {
            title: 'Start Time',
            render: (record: any) => (
                <Text>
                    {Array.isArray(record?.time) && record?.time?.length > 0 ? record.time[0]?.start : '-'}
                </Text>
            ),
            key: 'start_time',
        },
        {
            title: 'End Time',
            render: (record: any) => (
                <Text>
                    {Array.isArray(record?.time) && record?.time?.length > 0 ? record.time[0]?.end : '-'}
                </Text>
            ),
            key: 'end_time',
        },
        {
            title: 'Service Type',
            dataIndex: 'service_type',
            key: 'service_type',
        },
        {
            title: 'Status',
            render: (user: any) => (
                <div className="flex items-start flex-col">
                    {user?.status == 'ACTIVE' && <Tag color="green">Active</Tag>}
                    {user?.status == 'INACTIVE' && <Tag color="red">Inactive</Tag>}
                    {user?.status == 'SUSPENDED' && <Tag color="orange">Suspended</Tag>}
                    {user?.status == 'BANNED' && <Tag>Banned</Tag>}
                    {user?.status == 'SHADOW_BANNED' && <Tag>Shadow Banned</Tag>}
                    {user?.status == 'SHADOW_SUSPENDED' && <Tag color="orange">Shadow Suspended</Tag>}
                </div>
            ),
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: '',
            className: 'action-cell',
            render: (user: any) => ColumnActions(user),
        },
    ]
    const { request: getLayersRequest, loading: fetchingLayers } = useApi({
        api: getLayersList,
    });
    const { request: getLayerByIdRequest, loading: fetchingZones } = useApi({
        api: getLayerById,
    });
    const handleLayerChange = async (value: string, option: any) => {
        if (!value) {
            console.error('Layer ID is missing.');
            return;
        }
    
        setSelectedLayerName(option.children);
        setSelectedLayerId(value);
        setSelectedZones([]); 
        modalForm.setFieldsValue({ zone: [] });
    };
       useMount(() => {
        const fetchLayers = async () => {
            try {
                const response = await getLayersRequest({ page_size: 99999 });
                if (response?.data) {
                    setLayers(response.data.layers);
                }
            } catch (error) {
                console.error('Error fetching layers:', error);
            }
        };

        fetchLayers();
    });
    const handleZoneChange = (value: string[]) => {
        if (value.includes('all')) {
            if (selectedZones?.length === allZoneIds?.length) {
                setSelectedZones([]);
                modalForm.setFieldsValue({ zone: [] });
            } else {
                setSelectedZones(allZoneIds);
                modalForm.setFieldsValue({ zone: allZoneIds });
            }
        } else {
            setSelectedZones(value);
            modalForm.setFieldsValue({ zone: value });
        }
    };
    const { request: updateDriverSegmentRequest, loading: updatingDriverSegment } = useApi({
        api: ({ id, body }: { id: string; body: any }) => updateDriverSegment(id, body),
    });
    const handleAddServiceArea = async (values: any) => {

        const formattedTimes =
            selectedRange?.map(dateTime => dateTime?.format('HH:mm')) || [];

        const timePayload = formattedTimes?.length >= 2
            ? [{ start: formattedTimes[0], end: formattedTimes[1] }]
            : [];

        const zonePayload = selectedZones
            ?.map(zoneId => {
                const zone = zones.find((z: { id: string; }) => z.id === zoneId);
                return zone ? { id: zone.id, name: zone.name } : null;
            })
            .filter(Boolean);

        const payload = {
            id: segmentId,
            body: {
                name: segmentName,
                description: segmentDescription,
                status: 'ACTIVE',
                rules: [
                    {
                        type: 'driver_zone',
                        config: {
                            layer_id: selectedLayerId,
                            layer_name: selectedLayerName,
                            values: zonePayload,
                            days: selectedDays,
                            time: timePayload,
                            timezone: 'Asia/Manila',
                            status: status ? 'ACTIVE' : 'INACTIVE',
                            service_type: serviceType,
                        },
                    },
                ],
            },
        };
        try {
            const res = await updateDriverSegmentRequest(payload);

            if (!res.error) {
                notification.success({
                    message: 'Success',
                    description: 'Successfully updated the driver segment.',
                    duration: 3,
                    onClose: () => navigate(`/driver-segments/overview/${segmentId}/driver-service-area`),
                });

                confirmModal.close();
            } else {
                notification.error({
                    message: 'Error',
                    description: res.error.message,
                    duration: 3,
                });
            }
        } catch (error) {
            console.error('API Error:', error);
            notification.error({
                message: 'Error',
                description: 'Failed to update the driver segment.',
                duration: 3,
            });
        }
    };
    const getLayerByIdRequestRef = useRef(getLayerByIdRequest);

    useEffect(() => {
        const fetchZonesForSelectedLayer = async () => {
            if (!selectedLayerId || !getLayerByIdRequestRef.current) return;
    
            try {
                const response = await getLayerByIdRequestRef.current({ id: selectedLayerId });
    
                if (response?.data?.zones) {
                    setZones(response?.data?.zones);
    
                    setZoneOptions(
                        response?.data?.zones?.map((zone: any) => ({
                            value: zone?.id,
                            label: zone?.name,
                        }))
                    );
                } else {
                    setZones([]);
                    setZoneOptions([]);
                }
            } catch (error) {
                console.error('Error fetching zones for selected layer:', error);
                setZones([]);
                setZoneOptions([]);
            }
        };
    
        fetchZonesForSelectedLayer();
    }, [selectedLayerId]); // Runs only when selectedLayerId changes
    
    return (
        <>
            <Table columns={columns} dataSource={memoizedTableDataArray} />
            <Modal {...confirmModal} title="Edit Driver Zones" footer={null}>
                <div className="p-2">
                    <Form form={modalForm} layout="vertical" onFinish={handleAddServiceArea} initialValues={{ selectedDays, serviceType, timeRange: selectedRange, layer: selectedLayerName, status, zone: selectedZones }}>
                        <Form.Item
                            name="layer"
                            label="Layer"
                            rules={[{ required: true, message: 'Please select a layer' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Search and select a layer"
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                onChange={handleLayerChange}
                            >
                                {layers?.map(layer => (
                                    <Option key={layer?.id} value={layer?.id}>
                                        {layer?.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {selectedLayerId && (
                            <Form.Item
                                name="zone"
                                label="Select Zones"
                                rules={[{ required: true, message: 'Please select a zone' }]}
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Select Service Areas"
                                    style={{ width: '100%' }}
                                    value={selectedZones}
                                    onChange={handleZoneChange}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                        const label = option?.label;
                                        return (
                                            typeof label === 'string' &&
                                            label.toLowerCase().includes(input.toLowerCase())
                                        );
                                    }}
                                    dropdownRender={menu => (
                                        <>
                                            <div
                                                style={{ padding: '8px', borderBottom: '1px solid #f0f0f0' }}
                                                onMouseDown={e => e.preventDefault()}
                                            >
                                                <Checkbox
                                                    checked={selectedZones?.length === allZoneIds?.length}
                                                    indeterminate={
                                                        selectedZones?.length > 0 &&
                                                        selectedZones?.length < allZoneIds?.length
                                                    }
                                                    onChange={() => handleZoneChange(['all'])}
                                                >
                                                    Select All
                                                </Checkbox>
                                            </div>
                                            {menu}
                                        </>
                                    )}
                                >
                                    {zones?.map(zone => (
                                        <Option key={zone?.id} value={zone?.id} label={zone?.name}>
                                            <Checkbox checked={selectedZones?.includes(zone?.id)}>{zone?.name}</Checkbox>
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                        <div className="flex justify-end">
                            <Form.Item>
                                <Space>
                                    <Button
                                        htmlType="reset"
                                        onClick={() => {
                                            confirmModal.close();
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button onClick={() => modalForm.submit()} type="primary">
                                        Save
                                    </Button>
                                </Space>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </Modal>
        </>
    )
}

export default DriverServiceArea;
