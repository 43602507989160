import React, { useCallback, useState } from 'react';
import { Button, message, Tag } from 'antd/lib';
import { Table } from 'components';
import useMount from 'hooks/useMount';
import useApi from 'hooks/useApi';
import { getDriverSegmentList } from 'services/message.service';
import useFilter from 'hooks/useFilter';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Text } from 'components/basic';
import { DownOutlined } from '@ant-design/icons';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
// import CreateSegmentModal from './CreateSegmentModal';
import useModal from 'hooks/useModal';
import DeleteSegmentModal from './DeleteSegmentModal';
import moment from 'moment';

const DriverSegmentPage = () => {
    const [data, setData] = useState([]);
    const [segmentName, setSegmentName] = useState<string | null>(null);
    const [segmentIdToDelete, setSegmentIdToDelete] = useState<number | null>(null);
    const [pagination, setPagination]: any = useState({
        defaultCurrent: 1,
        defaultPageSize: 10,
    });
    const navigate = useNavigate();
    const deleteSegmentModal = useModal();

    const { modifyFilters, requestState } = useFilter({
        page_size: 10,
        page: 1,
    });
    const handleOnDeletetModal = useCallback(
        (id: number, name: string) => {
            setSegmentIdToDelete(id);
            setSegmentName(name);
            deleteSegmentModal.show();
        },
        [deleteSegmentModal],
    );

    const ColumnActions = useCallback(
        (user: any) => {
            return (
                <>
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    label: 'View',
                                    key: '1',
                                    onClick: () => {
                                        navigate(`/driver-segments/overview/${user.id}/drivers`);
                                    },
                                },
                                {
                                    label: 'Delete',
                                    key: '2',
                                    onClick: () => handleOnDeletetModal(user.id, user.name),
                                },
                            ],
                        }}
                    >
                        <Button type="ghost">
                            Action
                            <DownOutlined />
                        </Button>
                    </Dropdown>
                </>
            );
        },
        [navigate, handleOnDeletetModal],
    );

    const { request, loading } = useApi({
        api: getDriverSegmentList,
    });

    const columns = [

        {
            title: 'Segment ID',
            render: (user: any) => (
                <div className="flex items-center">
                    <Text type="uuid">{user.id}</Text>
                </div>
            ),
        },
        {
            title: 'Segment Name',
            id: 'name',
            dataIndex: 'name',
        },
        {
            title: 'Description',
            id: 'description',
            dataIndex: 'description',
        },
        {
            title: 'Drivers',
            id: 'drivers',
            dataIndex: 'current_size',
        },
        {
            title: 'Updated By',
            id: 'updated_by',
            dataIndex: 'modified_by',
        },
        {
            title: 'Updated at',
            key: 'updated_at',
            render: (user: any) => (
                <div>
                    {user?.updated_at ? (
                        <span className="cursor-pointer">
                            {moment
                                .utc(user?.updated_at)
                                .add(8, 'hour')
                                .format('MMMM DD, YYYY hh:mm:ss A')}
                        </span>
                    ) : (
                        <span>-</span>
                    )}
                </div>
            )
        },
        {
            title: 'Status',
            render: (user: any) => (
                <div className="flex items-start flex-col">
                    <div className="">
                        {user.status == 'ACTIVE' && <Tag color="green">Active</Tag>}
                        {user.status == 'INACTIVE' && <Tag color="red">INACTIVE</Tag>}
                    </div>
                </div>
            ),
        },
        {
            title: 'Action',
            render: (user: any) => ColumnActions(user),
        },
    ];

    useMount(() => {
        fetchSegmentsList(requestState);
    });

    const fetchSegmentsList = useCallback(
        async (requestState: {}) => {
            try {
                request(requestState)
                    .then(result => {
                        let d = result.data;
                        if (result?.error == null) {
                            let segments = d.data.segments.map((c: any) => {
                                return {
                                    ...c,
                                    key: c.id,
                                };
                            });
                            setData(segments);
                            let pagination = d.data.pagination;
                            let p = {
                                ...pagination,
                                current: pagination.page,
                                defaultCurrent: 1,
                                pageSize: pagination.page_size,
                                total: pagination.total_count,
                            };
                            setPagination(p);
                        } else {
                            message.error("Error in fetching segments' list");
                        }
                    })
                    .catch((error: any) => {
                        message.error(error.message);
                    });
            } catch (error) {
                throw error;
            }
        },
        [request],
    );

    const onTableChange = useCallback(
        async (pagination: any) => {
            const { current, pageSize } = pagination;
            const { requestState } = await modifyFilters({
                page_size: pageSize,
                page: current,
            });

            await fetchSegmentsList(requestState || {});
        },
        [fetchSegmentsList, modifyFilters],
    );

    return (
        <ListLayout
            title="Driver Segments"
            actionComponent={
                <div className="flex">
                    <div>
                        <Button type="primary" onClick={() => navigate('/driver-segments/create-segments')}>
                            Create Driver Segment
                        </Button>
                    </div>
                </div>
            }
        >
            <Table
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={{
                    ...pagination,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total: any) => `${total?.toLocaleString()} items`,
                }}
                scroll={{ x: 1000 }} // fix horizontal
                onChange={onTableChange}
            />
            <DeleteSegmentModal
                modal={deleteSegmentModal}
                segmentId={segmentIdToDelete}
                name={segmentName}
                onSuccess={() => {
                    fetchSegmentsList(requestState);
                }}
            />
        </ListLayout>
    );
};

export default DriverSegmentPage;
