import { Button, Card, Spin, Table, notification } from 'antd';
import { Text } from 'components/basic';
import DetailContentLayout from 'components/layouts/ContentLayout/DetailContentLayout';
import useApi from 'hooks/useApi';
import useMount from 'hooks/useMount';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAdjustmentById } from 'services/message.service';
import { CheckCircleTwoTone, CloseCircleTwoTone, InfoCircleTwoTone, DownloadOutlined } from '@ant-design/icons';
import { columns } from './columns';
import moment from 'moment';
import useFilter from 'hooks/useFilter';
import DownloadUploadedCSV from '../DownloadUploadedCSVButton';
import { Status } from 'enums/statusEnum';
import { FilterState, Pagination } from '../types';

const getRowMessage = (count: number, type: 'pending' | 'success' | 'failed'): string => {
    const isPlural = count > 1;
    const rowText = isPlural ? 'rows' : 'row';
    const verb = isPlural ? 'are' : 'is';

    switch (type) {
        case 'pending':
            return `${count} Number of ${rowText} ${verb} still pending`;
        case 'success':
            return `${count} Number of ${rowText} ${verb} successfully uploaded`;
        case 'failed':
            return `${count} Number of ${rowText} ${isPlural ? 'have errors' : 'has an error'}`;
        default:
            return '';
    }
};

const AdjustmentDetailedPage = () => {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState<Pagination>({
        defaultCurrent: 1,
        defaultPageSize: 10,
        current: 1,
        pageSize: 10,
        total: 0,
    });

    const { request, result, loading } = useApi({
        api: getAdjustmentById,
    });

    const { filterState, modifyFilters } = useFilter({
        page_size: 10,
        page: 1,
        status: Status.FAILED,
    });

    const fetchAdjustment = useCallback(
        async (filterState: Partial<FilterState>, id: string) => {
            try {
                const response = await request({ id, ...filterState });

                if (response.error) {
                    notification.error({
                        message: 'Error',
                        description: 'Error fetching adjustments.',
                        duration: 3,
                    });
                    return;
                }

                setData(response.data.adjustments);

                const { page, page_size, total_count } = response.data.pagination;
                const paginationResponse: Pagination = {
                    ...pagination,
                    current: page,
                    pageSize: page_size,
                    total: total_count,
                };

                setPagination(paginationResponse);
            } catch (error) {
                // Handle any unforeseen errors that might occur
                notification.error({
                    message: 'Unexpected Error',
                    description: 'There has been an unexpected error. Please try again later.',
                    duration: 3,
                });
            }
        },
        [request, pagination],
    );

    useMount(() => {
        if (id) {
            fetchAdjustment(filterState, id);
        }
    });

    const onTableChange = useCallback(
        async (pagination: Partial<Pagination>) => {
            const { current, pageSize } = pagination;

            const { requestState } = await modifyFilters({
                page_size: pageSize,
                page: current,
            });

            if (id && requestState) {
                await fetchAdjustment(requestState, id);
            }
        },
        [fetchAdjustment, modifyFilters, id],
    );

    if (loading) {
        return <Spin />;
    }

    const {
        created_at,
        total_adjustments,
        summary = { pending: 0, failed: 0, success: 0 },
    } = result?.data?.adjustment_job || {};

    const { pending, failed, success } = summary;

    return (
        <DetailContentLayout fallbackRoute="/bulk-top-up-deductions">
            <div className="flex justify-between items-center my-md">
                <Text type="title" className="font-semibold">
                    {`Top-up and Deduction Results on ${
                        result?.data && moment.utc(created_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A')
                    }`}
                </Text>
                {total_adjustments === success && (
                    <DownloadUploadedCSV type="primary" text="Download" transaction={result?.data?.adjustment_job} />
                )}
            </div>
            <div className="border border-solid border-primary border-l-4 p-md mb-md flex items-center justify-between">
                <div>
                    <Text type="title-small">Upload Summary</Text>
                    {pending > 0 && (
                        <div className="flex gap-2 items-center">
                            <InfoCircleTwoTone twoToneColor="#1890ff" />
                            <Text type="title-small">{getRowMessage(pending, 'pending')}</Text>
                        </div>
                    )}
                    {success > 0 && (
                        <div className="flex gap-2 items-center">
                            <CheckCircleTwoTone twoToneColor="#52c41a" />
                            <Text type="title-small">{getRowMessage(success, 'success')}</Text>
                        </div>
                    )}
                    {failed > 0 && (
                        <div className="flex gap-2 items-center">
                            <CloseCircleTwoTone twoToneColor="#FF4D4F" />
                            <Text type="title-small">{getRowMessage(failed, 'failed')}</Text>
                        </div>
                    )}
                </div>
                <div>
                    <DownloadUploadedCSV
                        type="default"
                        status={Status.FAILED}
                        transaction={result?.data?.adjustment_job}
                        text="Download Failed Rows"
                        disabled={!failed}
                    />
                </div>
            </div>

            {total_adjustments === success ? (
                <div className="bg-primary-light  flex items-center py-xl justify-center">
                    <Card className="w-1/3 flex flex-col items-center justify-center">
                        <img src="/images/biker-check.png" alt="biker with check mark" />
                        <Text type="title" className="font-semibold text-green-500 text-center">
                            You're all good
                        </Text>
                    </Card>
                </div>
            ) : (
                <div className="my-md">
                    <Table columns={columns} dataSource={data} pagination={pagination} onChange={onTableChange} />
                </div>
            )}
        </DetailContentLayout>
    );
};

export default AdjustmentDetailedPage;
