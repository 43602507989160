import { Button, notification } from 'antd';
import React, { useCallback } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import useApi from 'hooks/useApi';
import { getAdjustmentById } from 'services/message.service';
import { json2csv } from 'json-2-csv';
import { downloadCSVFile } from 'services/file.service';
import moment from 'moment';
import { Adjustment, ButtonType, Transaction, TransactionResponse } from './types';
import { Status } from 'enums/statusEnum';

const DownloadUploadedCSV = ({
    transaction,
    text = 'Download Uploaded CSV',
    type = 'link',
    ghost = false,
    status,
    disabled = false,
}: {
    transaction: TransactionResponse;
    text?: string;
    type?: ButtonType;
    ghost?: boolean;
    status?: Status.FAILED | Status.COMPLETE | undefined;
    disabled?: boolean;
}) => {
    const { request, loading } = useApi({
        api: getAdjustmentById,
    });

    const fetchAdjustment = useCallback(
        async (id: string) => {
            try {
                const response = await request({ id, page: 1, page_size: 10000, status });

                if (response?.error) {
                    notification.error({
                        message: 'Error',
                        description: `${response.error.errorCode}: ${response.error.message}`,
                        duration: 3,
                    });
                    return null;
                }

                return response;
            } catch (error) {
                notification.error({
                    message: 'Unexpected Error',
                    description: 'There has been an unexpected error. Please try again later.',
                    duration: 3,
                });
                return null;
            }
        },
        [request, status],
    );

    const handleDownload = async () => {
        if (!transaction.id) {
            notification.error({
                message: 'Error',
                description: 'No adjustments found for this transaction.',
                duration: 3,
            });
            return;
        }

        const response = await fetchAdjustment(transaction.id);
        if (!response?.data?.adjustments) {
            notification.error({
                message: 'Error',
                description: 'No adjustments found for this transaction.',
                duration: 3,
            });
            return;
        }

        // Filter only failed transactions
        const failedAdjustments = response.data.adjustments.filter(
            (adjustment: Adjustment) => adjustment.status === 'FAILED',
        );

        if (failedAdjustments.length === 0) {
            notification.info({
                message: 'No Failed Transactions',
                description: 'There are no failed transactions to download.',
                duration: 3,
            });
            return;
        }

        // Convert adjustments into CSV format
        const uploadedCsv = failedAdjustments.map((adjustment: Adjustment) => ({
            driver_id: adjustment.user_id,
            wallet_type: adjustment.wallet_type,
            reason: adjustment.reason,
            direction: adjustment.direction,
            amount: parseFloat(adjustment.amount),
            status: adjustment.status,
            failed_response: adjustment.failed_reason ?? '-', // Ensure "-" if undefined
        }));

        // Generate file name based on `created_at` in a readable format
        const fileName = `failed_transactions_${moment
            .utc(response.data.adjustment_job?.created_at)
            .format('YYYY-MM-DD_HH-mm-ss')}`;

        try {
            // Convert to CSV
            const csvData = await json2csv(uploadedCsv);
            downloadCSVFile(String(csvData), fileName);

            notification.success({
                message: 'Success',
                description: 'File successfully downloaded.',
                duration: 3,
            });
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to generate CSV file.',
                duration: 3,
            });
        }
    };

    return (
        <Button
            icon={<DownloadOutlined />}
            type={type}
            onClick={handleDownload}
            disabled={loading || disabled}
            loading={loading}
            ghost={ghost}
        >
            {loading ? 'Downloading..' : text}
        </Button>
    );
};

export default DownloadUploadedCSV;
