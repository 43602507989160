import { Button, Spin, Tag } from 'antd';
import { Status } from 'enums/statusEnum';
import React from 'react';
import { getStatusColor } from 'services/status.service';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import DownloadUploadedCSV from './DownloadUploadedCSVButton';
import { TransactionResponse } from './types';

const ViewResultsButton = ({ transaction }: { transaction: TransactionResponse }) => {
    const navigate = useNavigate();

    return (
        <Button
            type="primary"
            ghost
            disabled={transaction.status === Status.PENDING}
            onClick={() => navigate(`${transaction.id}`)}
        >
            View Results
        </Button>
    );
};

export const columns = [
    {
        title: 'Time',
        key: 'created_at',
        render: (transaction: TransactionResponse) => (
            <span>{moment.utc(transaction.created_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A')}</span>
        ),
    },
    {
        title: 'Operator',
        key: 'operator_name',
        render: (transaction: TransactionResponse) => transaction.operator_name || '-',
    },
    {
        title: 'Task',
        key: 'task',
        render: (transaction: TransactionResponse) => <DownloadUploadedCSV transaction={transaction} />,
    },
    {
        title: 'Status',
        key: 'status',
        render: (transaction: TransactionResponse) => {
            const color = getStatusColor(transaction.status);
            return (
                <div className="flex items-center gap-4">
                    <Tag color={color} className="uppercase">
                        {transaction.status}
                    </Tag>
                    {transaction.status === Status.PROCESSING && <Spin />}
                </div>
            );
        },
    },
    {
        title: 'Result',
        key: 'result',
        render: (transaction: TransactionResponse) => {
            return <ViewResultsButton transaction={transaction} />;
        },
    },
];
