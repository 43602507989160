import React from 'react';
import Text from 'components/basic/Typography/Text';
import Field from 'components/basic/Field/Field';
import moment from 'moment';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';


const DriverSegmentSummary = ({ data }: any) => {
    const navigate = useNavigate();
    return (
        <div className='flex gap-8 items-center'>
            <div>
                <Text
                    fontWeight="font-semibold"
                    className="mb-0 transition ease-in-out break-all"
                >
                    {data[0]?.data?.name}
                </Text>

            </div>
            <div>
                <Button size="small"
                 onClick={() =>
                    navigate(`/driver-segments/overview/${data[1]}/drivers/update-segment`, {
                      state: { segmentData: data[0]?.data },
                    })
                  }>
                    Edit
                </Button>
            </div>
            <div>
                <Field label="Description" value={data[0]?.data?.description} valueClassName="text-ellipsis break-all" />
            </div>
            <div>
                <Field label="Segment ID" value={data[1]} valueClassName="text-ellipsis break-all" />
            </div>
            <div>
                <Field label="Drivers" value={data[0]?.data?.current_size} valueClassName="text-ellipsis break-all" />
            </div>
            <div>
                <Field label="Created By" value={data[0]?.data?.modified_by} valueClassName="text-ellipsis break-all" />
            </div>
            <div>
                <Field
                    label="Created at"
                    value={data[0]?.data?.created_at ? moment(data[0]?.data?.created_at).format('YYYY-DD-MM') : '-'}
                />
            </div>
            <div>
                <Field
                    label="Updated at"
                    value={data[0]?.data?.updated_at ? moment(data[0]?.data?.updated_at).format('YYYY-DD-MM') : '-'}
                />
            </div>
        </div>
    );
};

export default DriverSegmentSummary;
