import { Status } from '../enums/statusEnum';

export const getStatusColor = (status: Status): string => {
    switch (status) {
        case Status.COMPLETE:
            return 'green';
        case Status.PROCESSING:
            return 'cyan';
        case Status.FAILED:
            return 'red';
        case Status.PENDING:
            return 'orange';
        default:
            return 'gray'; // For unexpected status
    }
};
