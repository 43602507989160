import { Card, Text } from 'components/basic';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DollarOutlined, UploadOutlined, ToolOutlined } from '@ant-design/icons';
import useMount from 'hooks/useMount';
import { useAuth0 } from '@auth0/auth0-react';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';

const SettingsPage = () => {
    const navigate = useNavigate();
    const [claims, setClaims] = React.useState<any>(null);
    const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();

    const hasViewAccessibility = (page: string) => {
        return (
            getPermissions(claims?.user_role, page)?.includes(Accessibility.VIEW) ||
            getPermissions(claims?.user_role, page)?.includes(Accessibility.ALL) ||
            getPermissions(claims?.user_role, page)?.includes(Accessibility.EDIT)
        );
    };

    useMount(() => {
        const detectUser = async () => {
            const accessToken = await getAccessTokenSilently();
            if (accessToken) {
                getIdTokenClaims().then(claims => {
                    setClaims(claims);
                });
            }
        };
        detectUser();
    });

    return (
        <div className="flex flex-col gap-4">
            <div className="grid grid-cols-4 gap-4">
                <Card
                    disabled={
                        !hasViewAccessibility(PagesEnum.GENERAL_SETTINGS) || claims?.user_role === 'ONBOARDING_MANAGER'
                    }
                    className="cursor-pointer bg-slate-50 hover:bg-slate-100 rounded p-8 transition"
                    onClick={() => navigate('fleet-management')}
                >
                    <div className="flex justify-between items-center">
                        <Text type="title">Fleet Management</Text>
                        <div>
                            <img src="/images/bike.svg" />
                            <img src="/images/bike.svg" />
                            <img src="/images/bike.svg" />
                        </div>
                    </div>
                    <Text type="label" className="mt-md">
                        Easily tweak fleet priorities and keep a tight rein on rider access in our fleet management
                        settings, guaranteeing super smooth and personalized transportation experiences.
                    </Text>
                </Card>
                <Card
                    disabled={
                        !hasViewAccessibility(PagesEnum.GENERAL_SETTINGS) || claims?.user_role === 'ONBOARDING_MANAGER'
                    }
                    className="cursor-pointer bg-slate-50 hover:bg-slate-100 rounded p-8 transition"
                    onClick={() => navigate('fare-config')}
                >
                    <div className="flex justify-between items-center">
                        <Text type="title">Fare Config</Text>
                        <div className="flex gap-1">
                            <DollarOutlined style={{ fontSize: '28px', color: '#13aee5' }} />
                            <DollarOutlined style={{ fontSize: '28px', color: '#13aee5' }} />
                            <DollarOutlined style={{ fontSize: '28px', color: '#13aee5' }} />
                        </div>
                    </div>
                    <Text type="label" className="mt-md">
                        View and edit your pricing configurations, including base price, surcharge, and distance rates.
                    </Text>
                </Card>

                {/* <Card
                    disabled={claims?.user_role !== 'SUPER_ADMIN'}
                    className="cursor-pointer bg-slate-50 hover:bg-slate-100 rounded p-8 transition"
                    onClick={() => navigate('surcharge-config')}
                >
                    <div className="flex justify-between items-center">
                        <Text type="title">Surcharge Config</Text>
                        <div className="flex gap-1">
                            <DollarOutlined
                                style={{ fontSize: '28px', color: '#13aee5' }}
                            />
                            <PlusCircleOutlined
                                style={{ fontSize: '28px', color: '#13aee5' }}
                            />
                            <PlusCircleOutlined
                                style={{ fontSize: '28px', color: '#13aee5' }}
                            />
                        </div>
                    </div>
                    <Text type="label" className="mt-md">
                        View and edit your pricing configurations, including base price,
                        surcharge, and distance rates.
                    </Text>
                </Card> */}

                <Card
                    disabled={
                        !hasViewAccessibility(PagesEnum.GENERAL_SETTINGS) ||
                        claims?.user_role === 'OPS_STRATEGY_2' ||
                        claims?.user_role === 'VISMIN_ONBOARDING'
                    }
                    className="cursor-pointer bg-slate-50 hover:bg-slate-100 rounded p-8 transition"
                    onClick={() => navigate('bulk-motorcycle-update')}
                >
                    <div className="flex justify-between items-center">
                        <Text type="title">Bulk Motorcycle Update</Text>
                        <div className="flex gap-1">
                            <UploadOutlined style={{ fontSize: '28px', color: '#13aee5' }} />
                        </div>
                    </div>
                    <Text type="label" className="mt-md">
                        Allows admin to upload CSV file containing changes in the motorcycle details upon request so
                        that bikers can use their new mc and continue to go online.
                    </Text>
                </Card>

                <Card style="dashed" className="rounded">
                    <div className="flex flex-col items-center gap-4">
                        <ToolOutlined style={{ fontSize: '24px', color: 'gray' }} />
                        <span className="text-gray-500 text-sm font-light">More settings coming soon!</span>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default SettingsPage;
