import React, { useState, useEffect, useRef } from 'react';
import { Button, Checkbox, Form, Modal, Select, Space, Switch, TimePicker, Upload, message, notification } from 'antd';
import type { UploadProps } from 'antd';
import Papa from 'papaparse';
import useApi from 'hooks/useApi';
import { getLayerById, getLayersList, updateDriverSegment, uploadDriverSegment, validateDriverSegment } from 'services/message.service';
import { useNavigate, useLocation } from 'react-router-dom';
import useModal from 'hooks/useModal';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import type { RangeValue } from 'rc-picker/lib/interface';
import useMount from 'hooks/useMount';
import { Text } from 'components/basic';
const { RangePicker } = TimePicker;
const { Option } = Select;
import moment from 'moment';

const DriverUpload: React.FC = () => {
    const { Dragger } = Upload;
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState<any[]>([]);
    const [csvData, setCsvData] = useState<any[]>([]);
    const [validationError, setValidationError] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const confirmModal = useModal();
    const exportData = location.state?.exportData
    const segmentData = location.state?.segmentData

    const [segmentName, setSegmentName] = useState(segmentData?.data?.name);
    const segmentId = location.state?.segmentId;
    const [segmentDescription, setSegmentDescription] = useState(segmentData?.data?.description)
    const [modalForm] = Form.useForm();
    const [selectedDays, setSelectedDays] = useState<CheckboxValueType[]>([]);
    const [selectedRange, setSelectedRange] = useState<RangeValue<moment.Moment> | null>(null);
    const [serviceType, setServiceType] = useState<string>('');
    const [status, setStatus] = useState<boolean>(false);
    const [layers, setLayers] = useState<any[]>([]);
    const [selectedLayerName, setSelectedLayerName] = useState<string | null>(null);
    const [selectedLayerId, setSelectedLayerId] = useState<string | null>(null);
    const [zones, setZones] = useState<any[]>([]);
    const [zoneOptions, setZoneOptions] = useState<{ value: string; label: string }[]>([]);
    const [selectedZones, setSelectedZones] = useState<string[]>([]);
    const [zoneArray, setZoneArray] = useState<any[]>([]);
    const [added, setAdded] = useState(false);
    const allZoneIds = zones.map(zone => zone.id);
    const { request: validateCSVRequest, loading: validatingCSV } = useApi({
        api: validateDriverSegment,
    });
    const { request: uploadDriversRequest, loading: uploadingDrivers } = useApi({
        api: ({ id, body }: { id: string; body: any }) => uploadDriverSegment(id, body),
    });
    const { request: updateDriverSegmentRequest, loading: updatingDriverSegment } = useApi({
        api: ({ id, body }: { id: string; body: any }) => updateDriverSegment(id, body),
    });
    const { request: getLayersRequest, loading: fetchingLayers } = useApi({
        api: getLayersList,
    });
    const { request: getLayerByIdRequest, loading: fetchingZones } = useApi({
        api: getLayerById,
    });
    const handleLayerChange = async (value: string, option: any) => {
        if (!value) {
            console.error('Layer ID is missing.');
            return;
        }
        setSelectedLayerName(option.children);
        setSelectedLayerId(value);
        setSelectedZones([]);
        modalForm.setFieldsValue({ zone: [] });
    };
    useEffect(() => {
        if (exportData?.values?.length) {
            setSelectedLayerName(exportData.layer_name);
            setSelectedDays(exportData.days);
            setServiceType(exportData.service_type);
            setZones(exportData.values);
            const zoneOptions = exportData.values.map((zone: any) => ({
                value: zone.id,
                label: zone.name,
            }));
            setZoneOptions(zoneOptions);
            setZoneArray(zoneOptions.map((zone: { label: any; }) => zone.label));
            setSelectedZones(exportData.values.map((zone: { id: any; }) => zone.id));
            setStatus(exportData.status);
            setSelectedLayerId(exportData.layer_id);
            if (exportData.time?.length) {
                const formattedTime: RangeValue<moment.Moment> = [
                    moment(exportData.time[0].start, "HH:mm"),
                    moment(exportData.time[0].end, "HH:mm"),
                ];
                setSelectedRange(formattedTime);
            } else {
                setSelectedRange(null);
            }
            setSegmentName(segmentData?.data?.name);
            setSegmentDescription(segmentData?.data?.description);
        }
    }, [exportData, segmentData?.data?.name, segmentData?.data?.description]);
    useMount(() => {
        const fetchLayers = async () => {
            try {
                const response = await getLayersRequest({ page_size: 99999 });
                if (response?.data) {
                    setLayers(response.data.layers);
                }
            } catch (error) {
                console.error('Error fetching layers:', error);
            }
        };
        fetchLayers();
    });
    const uploadProps: UploadProps = {
        accept: '.csv',
        beforeUpload: file => {
            const isCsv = file.type === 'text/csv';
            if (!isCsv) {
                message.error('Only CSV files are allowed!');
                return Upload.LIST_IGNORE;
            }
            setFileList([file]);
            parseCSV(file);
            return false;
        },
        onRemove: () => {
            setFileList([]);
            setCsvData([]);
        },
        fileList,
    };

    const handleAddServiceArea = async (values: any) => {
        confirmModal.close();
        setAdded(true);
    };
    const parseCSV = (file: File) => {
        const reader = new FileReader();
        reader.onload = async ({ target }) => {
            if (target?.result) {
                Papa.parse<string[]>(target.result as string, {
                    header: false,
                    skipEmptyLines: true,
                    complete: result => {
                        const data = result.data as string[][];

                        const isValidCSV = data.every(row => row.length === 1);
                        if (!isValidCSV) {
                            setValidationError(true);
                            return;
                        }
                        else{
                            setValidationError(false)
                        }
                        const driverIds = result.data.map((row: string[]) => row[0]?.trim().replace(/"/g, ''));
                        setCsvData(driverIds);
                    },
                });
            }
        };
        reader.readAsText(file);
    };

    const handleCSVValidation = async (driverIds: string[]) => {
        try {
            const validationResponse = await validateCSVRequest({ driver_ids: driverIds });
            
            if (validationResponse?.data?.data?.length <= 1) {
                setValidationError(false);
                return true;
            }

            setValidationError(true);
            notification.error({
                message: 'CSV Validation Failed',
                description: validationResponse.error.message,
            });
            return false;
        } catch (error) {
            notification.error({
                message: 'Validation Error',
                description: 'An error occurred while validating the CSV.',
            });
            return false;
        }
    };

    const uploadDrivers = async (driverIds: string[]) => {
        try {
            const uploadResponse = await uploadDriversRequest({
                id: segmentId,
                body: { driver_ids: driverIds },
            });

            if (uploadResponse.error) {
                notification.error({
                    message: 'Upload Failed',
                    description: uploadResponse.error.message,
                    duration: 3,
                });
                return false;
            } else {
                notification.success({
                    message: 'Bulk Upload',
                    description: (
                        <p>
                           <b>{fileList[0].name}</b> successfully uploaded.
                        </p>
                    ),
                });
                return true;
            }
        } catch (error) {
            notification.error({
                message: 'Upload Error',
                description: 'An error occurred while uploading the drivers.',
            });
            return false;
        }
    };
    const handleSubmit = async () => {
        if (csvData.length === 0) {
            notification.warning({
                message: 'No CSV Data',
                description: 'Please upload a valid CSV file before submitting.',
            });
            return;
        }

        const isValid = await handleCSVValidation(csvData);
        if (!isValid) return; // Stop if validation fails

        const isUploaded = await uploadDrivers(csvData);
        if (!isUploaded) return; // Stop if upload fails

        // If `selectedLayerName` is not set, stop execution here
        if (!selectedLayerName) {
            navigate(`/driver-segments/overview/${segmentId}/driver-service-area`)
            return;
        }

        // Proceed with segment update if `selectedLayerName` exists
        const formattedTimes =
            selectedRange?.map(dateTime => dateTime?.format('HH:mm')) || [];
        const timePayload = formattedTimes.length >= 2 ? [{ start: formattedTimes[0], end: formattedTimes[1] }] : [];

        const zonePayload = selectedZones
            .map(zoneId => {
                const zone = zones.find(z => z.id === zoneId);
                return zone ? { id: zone.id, name: zone.name } : null;
            })
            .filter(Boolean);

        const payload = {
            id: segmentId,
            body: {
                name: segmentName,
                description: segmentDescription,
                status: 'ACTIVE',
                rules: [
                    {
                        type: 'driver_zone',
                        config: {
                            layer_id: selectedLayerId,
                            layer_name: selectedLayerName,
                            values: zonePayload,
                            days: selectedDays,
                            time: timePayload,
                            timezone: 'Asia/Manila',
                            status: status ? 'ACTIVE' : 'INACTIVE',
                            service_type: serviceType,
                        },
                    },
                ],
            },
        };

        try {
            const res = await updateDriverSegmentRequest(payload);
            if (!res.error) {
                notification.success({
                    message: 'Success',
                    description: 'Successfully updated the driver segment.',
                    duration: 3,
                    onClose: () => navigate(`/driver-segments/overview/${segmentId}/driver-service-area`),
                });
            } else {
                notification.error({
                    message: 'Error',
                    description: res.error.message,
                    duration: 3,
                });
            }
        } catch (error) {
            console.error('API Error:', error);
            notification.error({
                message: 'Error',
                description: 'Failed to update the driver segment.',
                duration: 3,
            });
        }
    };
    const handleZoneChange = (value: string[]) => {
        if (value.includes('all')) {
            if (selectedZones?.length === allZoneIds?.length) {
                setSelectedZones([]);
                modalForm.setFieldsValue({ zone: [] });
            } else {
                setSelectedZones(allZoneIds);
                modalForm.setFieldsValue({ zone: allZoneIds });
            }
        } else {
            setSelectedZones(value);
            modalForm.setFieldsValue({ zone: value });
        }
    };
    const handleCancel = () => {
        setAdded(false);
        form.resetFields();
        setSelectedLayerName(null);
        setFileList([]);
    };
    const getLayerByIdRequestRef = useRef(getLayerByIdRequest);
    useEffect(() => {
        const fetchZonesForSelectedLayer = async () => {
            if (!selectedLayerId || !getLayerByIdRequestRef.current) return;
            try {
                const response = await getLayerByIdRequestRef.current({ id: selectedLayerId });

                if (response?.data?.zones) {
                    setZones(response.data.zones);

                    setZoneOptions(
                        response.data.zones.map((zone: any) => ({
                            value: zone.id,
                            label: zone.name,
                        }))
                    );
                } else {
                    setZones([]);
                    setZoneOptions([]);
                }
            } catch (error) {
                console.error('Error fetching zones for selected layer:', error);
                setZones([]);
                setZoneOptions([]);
            }
        };

        fetchZonesForSelectedLayer();
    }, [selectedLayerId]);
    return (
        <div className="flex justify-center">
            <div className="w-2/5">
                <div>
                    <button
                        className="border-0 bg-transparent text-[#636A6C]"
                        onClick={() => navigate(-1)}
                    >
                        <div>
                            <img src="/images/goback.svg" />
                            <span className="ml-2 text-sm">Go Back</span>
                        </div>
                    </button>
                </div>
                <div className="border border-solid rounded-xl border-[#E7F0F3] p-8">
                    <h5>Driver Segment</h5>
                    <Form form={form} name="validateOnly" layout="vertical" autoComplete="off" onFinish={handleSubmit}>
                        <div className="flex justify-between items-center mb-2">
                            <span className='text-cyanBlue-grayShade'>Please upload file in .csv format only.</span>
                        </div>
                        <Form.Item name="csvFile" className="w-full">
                            <Dragger {...uploadProps} className="w-full mt-2 bg-white !rounded-[8px]">
                                <Button
                                    className="w-full py-lg"
                                    style={{
                                        border: 'none',
                                        background: 'none',
                                        padding: '10px 0px',
                                        margin: '0px',
                                        display: 'contents',
                                    }}
                                    type="primary"
                                >
                                    <div className="m-2 py-md flex flex-col items-center">
                                        <img src="/images/uploadIcon.svg" />
                                        <p className="m-2 font-semibold text-md w-5/6" style={{ whiteSpace: 'normal' }}>
                                            Click to upload or drag and drop your file here
                                        </p>
                                        <span style={{ color: 'gray' }}>Maximum file size 25 MB</span>
                                    </div>
                                </Button>
                            </Dragger>

                            {validationError && (
                                <div className="flex">
                                    <p className="bg-[#FFFBE6] p-3 text-xs border-[#FFE58F] border border-solid w-5/6 flex-auto">
                                        Invalid or Empty Field <br />
                                        The uploaded file contains empty fields or invalid data. The files should be at
                                        least 1,000 rows and maximum of 25 mb in file size.
                                    </p>
                                </div>
                            )}
                        </Form.Item>
                        <div className="flex justify-between">
                            <div>
                                <h5>Driver Service Area (Optional)</h5>
                            </div>
                            {!added && (
                                <div>
                                    <button
                                        type="button"
                                        onClick={() => confirmModal.show()}
                                        className="bg-transparent border-0 text-[#0084B0] text-sm"
                                    >
                                        + Add
                                    </button>
                                </div>
                            )}
                        </div>
                        {
                            selectedLayerName ? (
                                <div className="mb-4 border border-solid border-[#E7F0F3] p-md">
                                    <div className="border border-solid border-[#E7F0F3] border-t-0 border-x-0">
                                        <Text type="title" className="font-semibold">
                                            {selectedLayerName}
                                        </Text>
                                    </div>
                                    <div className="flex items-center justify-between mt-md">
                                        <div>
                                            <Text type="title" className="font-semibold ">
                                                Service Area
                                            </Text>
                                            {selectedZones?.length > 0 &&
                                                (() => {
                                                    const zoneNames = selectedZones
                                                        .map(zoneId => zones.find(z => z.id === zoneId)?.name)
                                                        .filter(Boolean);
                                                    return zoneNames?.length > 3
                                                        ? `${zoneNames.slice(0, 3).join(', ')} and ${zoneNames?.length - 3
                                                        } more`
                                                        : zoneNames.join(', ');
                                                })()}
                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                onClick={() => confirmModal.show()}
                                                className="bg-transparent border-0 text-[#0084B0] text-sm"
                                            >
                                                View & Edit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        <div className="flex justify-end">
                            <Form.Item>
                                <Space>

                                    <Button onClick={handleCancel} htmlType="reset">
                                        Cancel
                                    </Button>
                                    <Button type="primary" htmlType="submit" loading={updatingDriverSegment}>
                                        Save
                                    </Button>
                                </Space>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </div>
            <Modal {...confirmModal} title="Add Driver Service Area" footer={null}>
                <div className="p-2">
                    <Form form={modalForm} layout="vertical" onFinish={handleAddServiceArea} initialValues={{ selectedDays, serviceType, timeRange: selectedRange, layer: selectedLayerName, status, zone: selectedZones }}>
                        <Form.Item
                            name="selectedDays"
                            label="Select Day(s)"
                            rules={[{ required: true, message: 'Please select at least one day' }]}
                        >
                            <Checkbox.Group
                                options={['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']}
                                value={selectedDays}
                                onChange={setSelectedDays}
                            />
                        </Form.Item>
                        <Form.Item
                            name="timeRange"
                            label="Select Time"
                            rules={[{ required: true, message: 'Please select a time range' }]}
                        >
                            <RangePicker
                                onChange={(values) => {
                                    setSelectedRange(values as RangeValue<moment.Moment>); // Cast to correct type
                                    modalForm.setFieldsValue({ timeRange: values });
                                }}
                                value={selectedRange}
                                format="HH:mm"
                            />
                        </Form.Item>
                        <Form.Item
                            name="serviceType"
                            label="Service Type"
                            rules={[{ required: true, message: 'Please select a service type' }]}
                        >
                            <Space wrap>
                                {['passenger_bike', 'padala_bike', 'passenger_four_seater', 'passenger_six_seater'].map(type => (
                                    <Button
                                        key={type}
                                        type={serviceType === type ? 'primary' : 'default'} // Use state instead of getFieldValue
                                        onClick={() => {
                                            const newType = serviceType === type ? '' : type;
                                            setServiceType(newType);
                                            modalForm.setFieldsValue({ serviceType: newType }); // Ensure form updates
                                        }}
                                    >
                                        {type}
                                    </Button>
                                ))}
                            </Space>
                        </Form.Item>
                        <Form.Item name="status" label="Status" valuePropName="checked">
                            <Switch
                                checked={status}
                                onChange={checked => {
                                    setStatus(checked);
                                    modalForm.setFieldsValue({ status: checked });
                                }}
                                checkedChildren="ACTIVE"
                                unCheckedChildren="INACTIVE"
                            />
                        </Form.Item>
                        <Form.Item
                            name="layer"
                            label="Layer"
                            rules={[{ required: true, message: 'Please select a layer' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Search and select a layer"
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    (option.children ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                onChange={handleLayerChange}
                            >
                                {layers.map(layer => (
                                    <Option key={layer.id} value={layer.id}>
                                        {layer.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {selectedLayerId && (
                            <Form.Item
                                name="zone"
                                label="Select Zones"
                                rules={[{ required: true, message: 'Please select a zone' }]}
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Select Service Areas"
                                    style={{ width: '100%' }}
                                    value={selectedZones}
                                    onChange={handleZoneChange}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                        const label = option?.label;
                                        return (
                                            typeof label === 'string' &&
                                            label.toLowerCase().includes(input.toLowerCase())
                                        );
                                    }}
                                    dropdownRender={menu => (
                                        <>
                                            <div
                                                style={{ padding: '8px', borderBottom: '1px solid #f0f0f0' }}
                                                onMouseDown={e => e.preventDefault()}
                                            >
                                                <Checkbox
                                                    checked={selectedZones?.length === allZoneIds?.length}
                                                    indeterminate={
                                                        selectedZones?.length > 0 &&
                                                        selectedZones?.length < allZoneIds?.length
                                                    }
                                                    onChange={() => handleZoneChange(['all'])}
                                                >
                                                    Select All
                                                </Checkbox>
                                            </div>
                                            {menu}
                                        </>
                                    )}
                                >
                                    {zones?.map(zone => (
                                        <Option key={zone.id} value={zone.id} label={zone.name}>
                                            <Checkbox checked={selectedZones.includes(zone.id)}>{zone.name}</Checkbox>
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                        <div className="flex justify-end">
                            <Form.Item>
                                <Space>
                                    <Button
                                        htmlType="reset"
                                        onClick={() => {
                                            confirmModal.close();
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button onClick={() => modalForm.submit()} type="primary">
                                        Save
                                    </Button>
                                </Space>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </Modal>
        </div>
    );
};
export default DriverUpload;
