import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Space, notification } from 'antd';
import useApi from 'hooks/useApi';
import { updateDriverSegment } from 'services/message.service';
import { useNavigate } from 'react-router-dom';
import useModal from 'hooks/useModal';
import { useParams,useLocation } from 'react-router-dom';

const UpdateDriverSegment: React.FC = () => {
    const location = useLocation();
    const segmentData = location.state?.segmentData;
    const [form] = Form.useForm();
    const confirmModal = useModal();
    const [loading, setLoading] = useState(false);
    const [segmentId, setSegmentId] = useState('');
    const [segmentName, setSegmentName] = useState(segmentData?.name);
    const [segmentDescription, setSegmentDescription] = useState(segmentData?.description);
    const navigate = useNavigate();
    let { id } = useParams();

    const { request: updateDriverSegmentRequest, loading: updatingDriverSegment } = useApi({
        api: ({ id, body }: { id: string; body: any }) => updateDriverSegment(id, body),
    });
    useEffect(() => {
        if (segmentData) {
            form.setFieldsValue({
                name: segmentData.name || '',
                description: segmentData.description || '',
            });
        }
    }, [segmentData, form]);
    
    const handleSubmit = async () => {
        const values = form.getFieldsValue(); // Get form data
        const payload = {
            id: id,
            body: {
                name: values.name,
                description: values.description,
                status: segmentData?.status,
                rules: segmentData?.rules
            }
        };

        try {
            const res = await updateDriverSegmentRequest(payload);
            if (!res.error) {
                setSegmentName(res?.data?.data?.name);
                setSegmentDescription(res?.data?.data?.description);
                navigate(`/driver-segments/overview/${id}/drivers`)
                notification.success({
                    message: 'Success',
                    description: `Driver Segment ${res?.data?.data?.name} updated.`,
                    duration: 3,
                });
            } else {
                notification.error({
                    message: 'Error',
                    description: res.error.message,
                    duration: 2,
                });
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to submit data.',
                duration: 2,
            });
        }
    };

   

    return (
        <div className="flex justify-center">
            <div className="w-2/5">
                <div>
                    <button
                        className="border-0 bg-transparent text-[#636A6C] mb-2"
                        onClick={() => navigate(`/driver-segments/overview/${id}/drivers`)}
                    >
                        <div>
                            <img src="/images/goback.svg" />
                            <span className="ml-2 text-sm">Go Back</span>
                        </div>
                    </button>
                </div>
                <div className="border border-solid rounded-xl border-[#E7F0F3] p-8">
                    <h5 className="border border-solid border-b-[#E7F0F3] border-t-0 border-l-0 border-r-0">
                        Driver Segment
                    </h5>
                    <Form form={form} name="validateOnly" layout="vertical" autoComplete="off" onFinish={handleSubmit}>
                        <Form.Item
                            name="name"
                            label="Segment Name"
                            rules={[
                                { required: true },
                                { max: 50, message: 'Segment Name cannot exceed 50 characters' },
                            ]}
                        >
                            <Input maxLength={51} />
                        </Form.Item>
                        <Form.Item name="description" label="Description">
                            <Input />
                        </Form.Item>
                        <div className="flex justify-end">
                            <Form.Item>
                                <Space>
                                    <Button htmlType="reset" onClick={() => navigate(`/driver-segments/overview/${id}/drivers`)}>
                                        Cancel
                                    </Button>
                                    <Button type="primary" htmlType="submit" loading={updatingDriverSegment}>
                                        Save
                                    </Button>
                                </Space>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default UpdateDriverSegment;
