import React, { useCallback, useContext, useState } from 'react';
import { Table, Text } from 'components/basic';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import InstallmentFilter from './InstallmentsFilter.tsx/InstallmentsFilter';
import useFilter from 'hooks/useFilter';
import useApi from 'hooks/useApi';
import { getScheduleList, getInstallmentsList, getDriversList } from 'services/message.service';
import { Dropdown } from 'components/basic';
import { DownOutlined } from '@ant-design/icons';
import { Avatar, Button, Skeleton, Tag, Tooltip } from 'antd/lib';
import DataModal from 'components/basic/Modal/DataModal/DataModal';
import useModal from 'hooks/useModal';
import useMount from 'hooks/useMount';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { AuthUserContext } from 'components/context/AuthUserContext';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';

const InstallmentPage = () => {
    const dataModal = useModal();
    const [data, setData] = useState([]);
    const [modalData, setModalData] = useState([]);
    const token = JSON.parse(localStorage.getItem('userToken') || '{}');
    const userRole = useContext(AuthUserContext);
    const hasActionAccess = (page: string) => {
        return (
            getPermissions(userRole, page)?.includes(Accessibility.ALL) ||
            getPermissions(userRole, page)?.includes(Accessibility.EDIT)
        );
    };
    const [pagination, setPagination]: any = useState({
        defaultCurrent: 1,
        defaultPageSize: 10,
    });

    const { modifyFilters, requestState } = useFilter({
        page_size: 10,
        page: 1,
        search_by: '',
        search_key: '',
        driver_id: '',
        installment_type: '',
        user_id: '',
    });

    const { request, loading } = useApi({
        api: getInstallmentsList,
    });

    useMount(() => {
        fetchInstallments(requestState);
    });

    const { request: getSchedule } = useApi({
        api: getScheduleList,
    });

    const { request: getDriversRequest, loading: getDriversLoading } = useApi({
        api: getDriversList,
    });

    const installmentSchedule = (id: string) => {
        fetchSchedule(id);
    };

    const fetchInstallments = useCallback(
        async (requestState: {}) => {
            try {
                const result = await request(requestState);
                let d = result.data;
                let installment = d.installments.map((c: any) => {
                    return {
                        ...c,
                        key: c.id,
                    };
                });
                setData(installment);
                let pagination = d.pagination;
                let p = {
                    current: pagination.page,
                    defaultCurrent: 1,
                    pageSize: pagination.page_size,
                    total: pagination.total_count,
                };
                setPagination(p);

                const items = d.installments;
                const uniqueDriverIds: string[] = [];
                items.forEach((c: any) => {
                    if (uniqueDriverIds.indexOf(c.user_id) === -1) {
                        uniqueDriverIds.push(c.user_id);
                    }
                });

                const uniqueDriverIdsString = uniqueDriverIds.join(',');

                const drivers = await getDriversRequest({
                    page_size: 100,
                    page: 1,
                    driver_ids: uniqueDriverIdsString,
                });

                const installments = d.installments.map((c: any) => {
                    const driver = drivers?.data?.data?.drivers?.find((d: any) => d.id === c.user_id);

                    if (driver) {
                        c.public_id = driver?.public_id;
                        c.name = (driver?.first_name + ' ' + driver?.last_name).trim();
                        c.avatar_url = driver?.avatar_url;
                    } else {
                        c.name = 'Driver Not Found';
                    }

                    return c;
                });
                setData(installments);
            } catch (error) {
                throw error;
            }
        },
        [request, getDriversRequest],
    );

    const fetchSchedule = useCallback(
        async (id: string) => {
            try {
                const result = await getSchedule({ token, id });
                let d = result.data;
                let schedules = d.schedules.map((c: any) => {
                    let amount = `PHP ${c.amount.toFixed(2)}`;
                    let status = c.settled_at ? 'Settled' : c.settlement_retry_count < 1 ? 'Pending' : 'Failed';

                    return {
                        ...c,
                        key: c.id,
                        amount,
                        status,
                    };
                });
                setModalData(schedules);
            } catch (error) {
                throw error;
            }
        },
        [getSchedule, token],
    );

    const onTableChange = useCallback(
        async (pagination: any) => {
            const { current, pageSize } = pagination;
            const { requestState } = await modifyFilters({
                page_size: pageSize,
                page: current,
            });
            await fetchInstallments(requestState || {});
        },
        [fetchInstallments, modifyFilters],
    );

    const onFilterSubmit = useCallback(
        (filter: any) => {
            modifyFilters({
                user_id: filter.driverId,
                installment_type: filter.installmentType,
            });

            fetchInstallments({
                ...requestState,
                page: 1,
                user_id: filter.driverId,
                installment_type: filter.installmentType,
            });
        },
        [modifyFilters, fetchInstallments, requestState],
    );

    const ColumnActions = (installment: any) => {
        return (
            <Dropdown
                menu={{
                    items: [
                        {
                            label: 'View Schedule',
                            key: '1',
                            onClick: () => {
                                dataModal.show(installmentSchedule(installment.id));
                            },
                        },
                    ],
                }}
            >
                <Button>
                    Actions
                    <DownOutlined />
                </Button>
            </Dropdown>
        );
    };

    const navigate = useNavigate();

    const columns = [
        {
            title: 'Installment ID',
            render: (installment: any) => (
                <div className="flex items-center">
                    <Text type="uuid">{installment.id}</Text>
                </div>
            ),
        },
        {
            title: 'Driver ID',
            key: 'public_id',
            render: (installment: any) =>
                getDriversLoading ? (
                    <Skeleton round active paragraph={false} />
                ) : (
                    <div className="flex items-center">
                        {installment.public_id ? (
                            <a
                                href={`/drivers/${installment.user_id}`}
                                onClick={e => {
                                    e.preventDefault();
                                    navigate(`/drivers/${installment.user_id}/overview`);
                                }}
                            >
                                {installment.public_id}
                            </a>
                        ) : (
                            <span className="font-semibold">-</span>
                        )}
                    </div>
                ),
        },
        {
            title: 'Driver',
            key: 'name',
            render: (installment: any) =>
                getDriversLoading ? (
                    <Skeleton round active paragraph={false} />
                ) : (
                    <div className="flex items-center gap-2">
                        <Avatar src={installment.avatar_url} size="small" className="mr-2" />

                        <span>{`${installment.name}`}</span>
                    </div>
                ),
        },
        {
            title: 'Installment Type',
            key: 'type',
            render: (installment: any) => (
                <div>
                    {installment.type == 'BIKER_GEAR' ? <Tag color="blue">BIKER GEAR</Tag> : null}
                    {installment.type == 'INSURANCE' ? <Tag color="volcano">INSURANCE</Tag> : null}
                    {installment.type == 'SERVICE_FEE' ? <Tag color="magenta">SERVICE FEE</Tag> : null}
                    {installment.type != 'SERVICE_FEE' &&
                    installment.type != 'INSURANCE' &&
                    installment.type != 'BIKER_GEAR' ? (
                        <Tag color="cyan" className="uppercase">
                            {installment.type}
                        </Tag>
                    ) : null}
                </div>
            ),
        },
        {
            title: 'Total Amount',
            key: 'amount',
            render: (installment: any) => (
                <div className="w-full text-right font-semibold">PHP {installment.amount.toFixed(2)}</div>
            ),
        },
        {
            title: 'Created At',
            key: 'created_at',
            render: (installment: any) => (
                <div>
                    <span className="underline decoration-dotted cursor-pointer">
                        {moment.utc(installment.created_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A')}
                    </span>
                </div>
            ),
        },
        {
            title: 'Completed At',
            key: 'completed_at',
            render: (installment: any) => <div>{installment.completed_at ? installment.completed_at : '-'}</div>,
        },
        {
            title: '',
            render: (installment: any) => ColumnActions(installment),
        },
    ];

    const modalColumns = [
        {
            title: 'Deduction Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Settlement Schedule',
            dataIndex: 'scheduled_at',
            key: 'scheduled_at',
        },
        {
            title: 'Settled At',
            dataIndex: 'settled_at',
            key: 'settled_at',
        },
    ];
    return (
        <ListLayout
            title="Installments"
            searchComponent={<InstallmentFilter onFilterSubmit={onFilterSubmit} loading={loading} />}
        >
            <Table
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={{
                    ...pagination,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total: any) => `${total?.toLocaleString()} items`,
                }}
                onChange={onTableChange}
            />
            <DataModal
                title="Schedule for the installment's deductions"
                {...dataModal}
                modalColumns={modalColumns}
                modalData={modalData}
            />
        </ListLayout>
    );
};

export default InstallmentPage;
