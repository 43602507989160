import { Modal, notification } from 'antd/lib';
import { Text } from 'components/basic';
import useApi from 'hooks/useApi';
import React, { useCallback } from 'react';
import { updateOperatorStatus } from 'services/message.service';
import { convertValueToLabel, roles } from 'services/permission.service';

const UpdateStatusModal = ({ ...props }) => {
    const { name, status, currentRole, newStatus, id } = props.content || {};
    const { request, loading } = useApi({
        api: updateOperatorStatus,
    });

    const onUpdate = async () => {
        try {
            const body = {
                status: newStatus,
            };
            const res = await request({ id, body });
            if (res.data) {
                notification.success({
                    message: 'Success',
                    description: (
                        <div>
                            User <strong>{name}</strong> successfully{' '}
                            {status === 'ACTIVE' || status === 'active' ? 'deactivated.' : 'activated.'}.
                        </div>
                    ),
                    duration: 3,
                });
                props.close();
                props.refreshList();
            } else if (res.error) {
                notification.error({
                    message: 'Error',
                    description: (
                        <div>
                            <Text>
                                <strong>Error Code:</strong> {res.error.errorCode}
                            </Text>
                            <Text>
                                <strong>Error Message:</strong> {res.error.message}
                            </Text>
                            <Text>
                                <strong>Name: </strong> {name}
                            </Text>
                        </div>
                    ),
                    duration: 3,
                });
                props.close();
            }
        } catch (e) {
            notification.error({
                message: 'Something Went Wrong',
                description: (
                    <div>
                        Unable to update the role for <strong>{name}</strong>. Please try again later.
                    </div>
                ),
                duration: 3,
            });
            props.close();
        }
    };

    return (
        <Modal
            {...props}
            title={status === 'ACTIVE' || status === 'active' ? 'Deactivate User' : 'Activate User'}
            okText="Save"
            onOk={onUpdate}
            confirmLoading={loading}
            onCancel={() => {
                props.close();
            }}
        >
            <Text fontWeight="font-semibold">
                Are you sure you want to {status === 'ACTIVE' || status === 'active' ? 'deactivate' : 'activate'} this
                user?
            </Text>
            <p>
                Once{' '}
                {status === 'ACTIVE' || status === 'active' ? (
                    <span className="text-red-600">deactivated</span>
                ) : (
                    'activated'
                )}
                ,
                {status === 'ACTIVE' || status === 'active'
                    ? ' the user will no longer have access to the portal.'
                    : ' the user will regain access to the portal.'}
            </p>
            <div>
                <Text color="text-gray" fontWeight="font-semibold" className="mt-md mb-xs">
                    User Summary
                </Text>
                <div>
                    <div className="h-[2rem] border-solid border border-gray-300 py-xs px-md rounded-t-lg flex justify-between items-center">
                        <Text color="text-gray" fontWeight="font-semibold" size="text-sm">
                            Name
                        </Text>
                        <div className="text-right my-xs">
                            <Text fontWeight="font-semibold" size="text-md" className="leading-none">
                                {name}
                            </Text>
                        </div>
                    </div>
                    <div className="h-[2rem] border-solid border-t-0 border border-gray-300 py-xs px-md rounded-b-lg flex justify-between items-center">
                        <Text color="text-gray" fontWeight="font-semibold" size="text-sm">
                            Role
                        </Text>
                        <div className="text-right">
                            <Text fontWeight="font-semibold" size="text-md">
                                {convertValueToLabel(currentRole)}
                            </Text>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default UpdateStatusModal;
